import { gql } from 'apollo-boost';
import {
  endOfWeekWithOptions,
  endOfMonth,
  endOfYear,
  setHours,
  startOfMonth,
  startOfWeekWithOptions,
  startOfYear,
} from 'date-fns/fp';
import { parse } from 'date-fns';
import flow from 'lodash/fp/flow';

import { client } from '../../../apollo';

const now = new Date();
const noon = setHours(12);
const transportAbgangDateRangeFieldDefault = {
  value: 'transportAbgangsdatum',
  label: 'Transport Abgangsdatum',
};
const geplantesAnkunftsdatumDateRangeFieldDefault = {
  value: 'geplantesAnkunftsdatum',
  label: 'Geplantes Ankunftsdatum',
};
const startOfWeek = startOfWeekWithOptions({ weekStartsOn: 1 });
const endOfWeek = endOfWeekWithOptions({ weekStartsOn: 1 });

const getCarrierGroup = async (carrierGroupId) => {
  const query = gql`
    query DossierCarrierGroup {
      frachtfuehrerGruppen {
        id
        name
      }
    }
  `;

  const { data } = await client.query({ query, fetchPolicy: 'network-only' });
  const { frachtfuehrerGruppen = [] } = data || {};

  return (
    frachtfuehrerGruppen.find(
      (grp) => grp.id === parseInt(carrierGroupId, 10),
    ) || {}
  );
};

export const presets = {
  week: ({
    transportAbgangDateRangeField = transportAbgangDateRangeFieldDefault,
  }) => ({
    dateRangeField: transportAbgangDateRangeField,
    timeRange: {
      startDate: flow(startOfWeek, noon)(now),
      endDate: flow(endOfWeek, noon)(now),
    },
  }),
  kundenreferenz: ({ kundenreferenz }) => ({
    referenz: { value: kundenreferenz, label: kundenreferenz },
  }),
  yearMonth: ({
    yearMonth,
    transportAbgangDateRangeField = transportAbgangDateRangeFieldDefault,
  }) => {
    const date = parse(yearMonth, 'yyyy-MM', new Date(yearMonth));
    return {
      dateRangeField: transportAbgangDateRangeField,
      timeRange: {
        startDate: flow(startOfMonth, noon)(date),
        endDate: flow(endOfMonth, noon)(date),
      },
    };
  },
  carrierGroup: async ({
    carrierGroupId,
    transportAbgangDateRangeField = transportAbgangDateRangeFieldDefault,
  }) => {
    const frachtfuehrerGruppe = await getCarrierGroup(carrierGroupId);

    return {
      dateRangeField: transportAbgangDateRangeField,
      timeRange: {
        startDate: flow(startOfYear, noon)(now),
        endDate: flow(endOfYear, noon)(now),
      },
      frachtfuehrerGruppeId: {
        value: frachtfuehrerGruppe.id,
        label: frachtfuehrerGruppe.name,
      },
    };
  },
  carrierGroupCH: async ({
    carrierGroupId,
    transportAbgangDateRangeField = transportAbgangDateRangeFieldDefault,
  }) => {
    const frachtfuehrerGruppe = await getCarrierGroup(carrierGroupId);

    return {
      dateRangeField: transportAbgangDateRangeField,
      timeRange: {
        startDate: flow(startOfYear, noon)(now),
        endDate: flow(endOfYear, noon)(now),
      },
      frachtfuehrerGruppeId: {
        value: frachtfuehrerGruppe.id,
        label: frachtfuehrerGruppe.name,
      },
      empfaengerLand: { value: 'CH', label: 'CH' },
    };
  },

  sendungsnummer: async ({ code }) => {
    const query = gql`
      query sendungsnummern($sendungsnummer: String!) {
        sendungsnummern(sendungsnummer: $sendungsnummer, limit: 1) {
          id
        }
      }
    `;

    const { data } = await client.query({
      query,
      variables: { sendungsnummer: code },
      fetchPolicy: 'network-only',
    });
    const { sendungsnummern = [] } = data || {};
    const [{ id }] = sendungsnummern;

    return { id: { value: id, label: code } };
  },
  lieferungKennzeichen: async ({ lieferungKennzeichen }) => {
    return { lieferungKennzeichen: { value: lieferungKennzeichen, label: lieferungKennzeichen } };
  },
  consigneeCountryDistribution: ({
    country,
    geplantesAnkunftsdatumDateRangeField = geplantesAnkunftsdatumDateRangeFieldDefault,
  }) => ({
    dateRangeField: geplantesAnkunftsdatumDateRangeField,
    timeRange: {
      startDate: flow(startOfYear, noon)(now),
      endDate: flow(endOfYear, noon)(now),
    },
    ...(country && country !== 'Sonstige'
      ? { empfaengerLand: { value: country, label: country } }
      : {}),
  }),
  negativreporting: ({ servicetype, country, negativreportingLabels }) => ({
    servicetype: {
      value: servicetype,
      label:
        servicetype === 'Transport'
          ? negativreportingLabels?.generalCargo
          : servicetype,
    },
    performanceStatus: {
      value: 'DeliveryInVerzug',
      label: negativreportingLabels?.deliveryInVerzug,
    },
    ...(country && country !== 'Sonstige'
      ? { empfaengerLand: { value: country, label: country } }
      : {}),
  }),
  regellaufzeitauswertung: ({
    servicetype,
    performancestatus,
    startDate,
    endDate,
    country,
    regellaufzeitauswertungLabels,
    geplantesAnkunftsdatumDateRangeField = geplantesAnkunftsdatumDateRangeFieldDefault,
  }) => ({
    dateRangeField: geplantesAnkunftsdatumDateRangeField,
    timeRange: {
      startDate: new Date(startDate),
      endDate: flow(endOfMonth)(new Date(endDate)),
    },
    servicetype: {
      value: servicetype,
      label:
        servicetype === 'Transport'
          ? regellaufzeitauswertungLabels?.generalCargo
          : servicetype,
    },
    ...(performancestatus === 'DeliveredOnTime'
      ? {
          performanceStatus: {
            value: performancestatus,
            label: regellaufzeitauswertungLabels?.deliveredOnTime,
          },
        }
      : performancestatus === 'DeliveredDelayed'
      ? {
          performanceStatus: {
            value: performancestatus,
            label: regellaufzeitauswertungLabels?.deliveredDelayed,
          },
        }
      : {}),
    ...(country && country !== 'Sonstige'
      ? { empfaengerLand: { value: country, label: country } }
      : {}),
  }),
};
