import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Logger } from 'aws-amplify';

import { useFilterContext } from '../filter-context';
import { useTranslation } from 'react-i18next';

const logger = new Logger('usePreset');

function usePreset(filterId: string, preset: string, presets: any) {
  const { params } = useRouteMatch();
  const { t } = useTranslation();
  const { getFilter } = useFilterContext();
  const [state, setState] = useState<any>({});
  const isPreset = preset && !!presets[preset](params);

  useEffect(() => {
    const localizedParams = {
      ...params,
      transportAbgangDateRangeField: {
        value: 'transportAbgangsdatum',
        label: t(
          'transports.reports.dossier.presets.transportAbgangDateRangeField.transportAbgangsdatum',
          'Transport Abgangsdatum',
        ),
      },
      geplantesAnkunftsdatumDateRangeField: {
        value: 'geplantesAnkunftsdatum',
        label: t(
          'transports.reports.dossier.presets.geplantesAnkunftsdatumDateRangeField.geplantesAnkunftsdatum',
          'Geplantes Ankunftsdatum',
        ),
      },
      negativreportingLabels: {
        generalCargo: t(
          'transports.reports.dossier.presets.negativreportingLabels.generalCargo',
          'Stückgut',
        ),
        deliveryInVerzug: t(
          'transports.reports.dossier.presets.negativreportingLabels.deliveryInVerzug',
          'in Verzug',
        ),
      },
      regellaufzeitauswertungLabels: {
        generalCargo: t(
          'transports.reports.dossier.presets.regellaufzeitauswertungLabels.generalCargo',
          'Stückgut',
        ),
        deliveredOnTime: t(
          'transports.reports.dossier.presets.regellaufzeitauswertungLabels.deliveredOnTime',
          'zugestellt on time',
        ),
        deliveredDelayed: t(
          'transports.reports.dossier.presets.regellaufzeitauswertungLabels.deliveredDelayed',
          'nicht on time zugestellt',
        ),
      },
      openOrdersInDayLabels: {
        OPEN: t('transports.reports.presets.openOrdersInDay.OPEN', 'Offen'),
      },
      openOrdersOutDayLabels: {
        OPEN: t('transports.reports.presets.openOrdersOutDay.OPEN', 'Offen'),
      },
    };

    const getFilterState = async () => {
      const savedFilters = getFilter(filterId);
      const presetFilters =
        isPreset && (await presets[preset](localizedParams));

      console.log('LOCALIZEDPARAMS', presetFilters);
      logger.debug('getFilterState', {
        isPreset,
        savedFilters,
        presetFilters,
      });

      setState({ savedFilters, presetFilters });
    };

    getFilterState();
  }, [presets, preset, params, isPreset, getFilter, filterId, t]);

  const { savedFilters, presetFilters } = state;

  return {
    isPreset,
    filterState: savedFilters || presetFilters,
    presetFilters,
  };
}

export default usePreset;
