import React, { Fragment } from 'react';
import {
  Banner,
  Box,
  Card,
  Grid,
  Heading,
  Spinner,
  Text,
} from '@uniteplus/rhenus-design-system';
import { useTranslation } from 'react-i18next';
import StoragePerformance from './StoragePerformance';
import TransportPerformance from './TransportPerformance';
import StorageEvolution from './StorageEvolution';
import TransportEvolution from './TransportEvolution';
import TransportDistribution from './TransportDistribution';
import TransportConsigneeCountryDistribution from './TransportConsigneeCountryDistribution';
import TransportLaufzeitauswertung from './TransportLaufzeitauswertung';
import TransportLaufzeitauswertungNegativliste from './TransportLaufzeitauswertungNegativliste';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const enabledChartsQuery = gql`
  query EnabledChartsQuery {
    enabledCharts {
      chartTransportOverview
      chartInternationalTransports
      chartDeliveryTimeReportByCountryCargo
      chartNegativeReportByCountryCargo
      chartDeliveryTimeReportByCountryPackages
      chartNegativeReportByCountryPackages
      chartTransportsDevelopment
      chartDeliveriesByCountry
      chartStoragePerformanceToday
      chartStoragePerformanceSpreitenbach
      chartStoragePerformanceBasel
      chartStoragePerformanceSchaffhausen
      chartStoragePerformanceBuchs
    }
  }
`;

const Dashboard = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const history = useHistory();
  const { loading: loadingEnabledCharts, data: maybeEnabledChartsResultOrMayNot } = useQuery<any>(enabledChartsQuery);
  const showChart = (name: string): boolean => maybeEnabledChartsResultOrMayNot && maybeEnabledChartsResultOrMayNot.enabledCharts && maybeEnabledChartsResultOrMayNot.enabledCharts[name];
  const unequalNumberOfChartsShownBeforeStoragePerformanceAndAnyLocationStoragePerformanceChartShown = () => [
    showChart('chartTransportOverview'),
    showChart('chartInternationalTransports'),
    showChart('chartDeliveryTimeReportByCountryCargo'),
    showChart('chartNegativeReportByCountryCargo'),
    showChart('chartDeliveryTimeReportByCountryPackages'),
    showChart('chartNegativeReportByCountryPackages'),
    showChart('chartTransportsDevelopment'),
    showChart('chartDeliveriesByCountry'),
  ].filter(e => e).length % 2 === 1 && showChart('chartStoragePerformanceToday') && (
      showChart('chartStoragePerformanceSpreitenbach')
      || showChart('chartStoragePerformanceBasel')
      || showChart('chartStoragePerformanceSchaffhausen')
      || showChart('chartStoragePerformanceBuchs')
    );
  const moreThanOneStoragePerformanceLocationChartShown = () => [
    showChart('chartStoragePerformanceSpreitenbach'),
    showChart('chartStoragePerformanceBasel'),
    showChart('chartStoragePerformanceSchaffhausen'),
    showChart('chartStoragePerformanceBuchs')
  ].filter(e => e).length > 1 && showChart('chartStoragePerformanceToday');

  const cardHead = {
    p: 3,
    mb: '1px',
    bg: 'white',
    display: 'flex',
    alignItems: 'baseline',
  };
  const subheading = { fontSize: '14px', color: 'primary', ml: 1 };
  const cardContent = { p: 3, bg: 'white' };
  return (
    <Fragment>
      <Box mt={2} mx={3}>
        <Banner
          url="/img/tnt-image.jpg"
          sx={{ mb: 2, height: ['100px', '200px', '300px'] }}
        />
      </Box>
      {loadingEnabledCharts
        ? <Spinner size={32} />
        : <Grid gap={1} mx={2} mb={2} columns={[1, 1, 2]}>
          {showChart('chartTransportOverview') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.transportOverview.title', 'Transportüberblick')}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportPerformance />
              </Box>
            </Card>
          }
          {showChart('chartInternationalTransports') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t(
                    'dashboard.transportDistribution.title',
                    'Verteilung Transporte International',
                  )}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportDistribution />
              </Box>
            </Card>
          }
          {showChart('chartDeliveryTimeReportByCountryCargo') &&
            <Card>
              <Box sx={cardHead}>
                <Heading
                  onClick={() => history.push(`/regellaufzeitauswertung`)}
                  css="cursor: pointer;"
                >
                  {t(
                    'dashboard.standardDeliveryTimeEvaluation.generalCargo.title',
                    {
                      year: currentYear,
                      defaultValue: `Regellaufzeitauswertung pro Land (Stückgut, ${currentYear})`,
                    },
                  )}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportLaufzeitauswertung servicetype="Transport" />
              </Box>
            </Card>
          }
          {showChart('chartNegativeReportByCountryCargo') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t(
                    'dashboard.negativeReport.generalCargo.title',
                    'Negativreporting pro Land (Stückgut noch nicht zugestellt)',
                  )}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportLaufzeitauswertungNegativliste servicetype="Transport" />
              </Box>
            </Card>
          }
          {showChart('chartDeliveryTimeReportByCountryPackages') &&
            <Card>
              <Box sx={cardHead}>
                <Heading
                  onClick={() => history.push(`/regellaufzeitauswertung`)}
                  css="cursor: pointer;"
                >
                  {t('dashboard.standardDeliveryTimeEvaluation.parcel.title', {
                    year: currentYear,
                    defaultValue: `Regellaufzeitauswertung pro Land (Paket, ${currentYear})`,
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportLaufzeitauswertung servicetype="Paket" />
              </Box>
            </Card>
          }
          {showChart('chartNegativeReportByCountryPackages') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t(
                    'dashboard.negativeReport.parcel.title',
                    'Negativreporting pro Land (Paket noch nicht zugestellt)',
                  )}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportLaufzeitauswertungNegativliste servicetype="Paket" />
              </Box>
            </Card>
          }
          {showChart('chartTransportsDevelopment') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t(
                    'dashboard.transportDevelopment.title',
                    'Entwicklung der Transporte',
                  )}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportEvolution />
              </Box>
            </Card>
          }
          {showChart('chartDeliveriesByCountry') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.deliveryDistributionPerCountry.title', {
                    year: currentYear,
                    defaultValue: `Sendungsverteilung pro Land (${currentYear})`,
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <TransportConsigneeCountryDistribution />
              </Box>
            </Card>
          }
          {unequalNumberOfChartsShownBeforeStoragePerformanceAndAnyLocationStoragePerformanceChartShown() &&
            <Card />
          }
          {showChart('chartStoragePerformanceToday') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.storagePerformance.title', 'Lagerperformance')}
                </Heading>
                <Text sx={subheading}>
                  {t(
                    'dashboard.storagePerformance.subtitle',
                    '(Lageraufträge heute)',
                  )}
                </Text>
              </Box>
              <Box sx={cardContent}>
                <StoragePerformance />
              </Box>
            </Card>
          }
          {moreThanOneStoragePerformanceLocationChartShown() &&
            <Card />
          }
          {showChart('chartStoragePerformanceSpreitenbach') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.developmentGoodsIn/Out.variable.title', {
                    location: 'Spreitenbach',
                    defaultValue:
                      'Entwicklung Wareneingang / Warenausgang Spreitenbach',
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <StorageEvolution location="Spreitenbach" />
              </Box>
            </Card>
          }
          {showChart('chartStoragePerformanceBasel') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.developmentGoodsIn/Out.variable.title', {
                    location: 'Basel',
                    defaultValue: 'Entwicklung Wareneingang / Warenausgang Basel',
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <StorageEvolution location="Basel" locationKey="RCL Basel" />
              </Box>
            </Card>
          }
          {showChart('chartStoragePerformanceSchaffhausen') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.developmentGoodsIn/Out.variable.title', {
                    location: 'Schaffhausen',
                    defaultValue:
                      'Entwicklung Wareneingang / Warenausgang Schaffhausen',
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <StorageEvolution location="Schaffhausen" />
              </Box>
            </Card>
          }
          {showChart('chartStoragePerformanceBuchs') &&
            <Card>
              <Box sx={cardHead}>
                <Heading>
                  {t('dashboard.developmentGoodsIn/Out.variable.title', {
                    location: 'Buchs',
                    defaultValue: 'Entwicklung Wareneingang / Warenausgang Buchs',
                  })}
                </Heading>
              </Box>
              <Box sx={cardContent}>
                <StorageEvolution location="Buchs" />
              </Box>
            </Card>
          }
        </Grid>
      }
    </Fragment>
  );
};

export default Dashboard;
