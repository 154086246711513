import React, { FC } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import useChartsTheme from './useChartsTheme';
import {
  ChartProps,
  CustomizedAxisTick,
  handleClick,
  MissingData,
} from './shared';

const Chart: FC<ChartProps> = ({
  data,
  dataKeys,
  onClick = () => {},
  messages = {
    missingData: 'Für diese Ansicht sind aktuell keine Daten vorhanden.',
  },
  stacked=false,
  unit='',
  uvColor,
  pvColor
}) => {
  const { grid, fillColors } = useChartsTheme();
  const missingData = !data.length;

  return missingData ? (
    <MissingData>{messages.missingData}</MissingData>
  ) : (
    <ResponsiveContainer>
      <BarChart data={data} onClick={!stacked ? handleClick('chart', onClick) : () => {}}>
        <CartesianGrid
          strokeDasharray={grid.strokeDasharray}
          stroke={grid.stroke}
        />
        <XAxis dataKey={dataKeys.xAxis} tick={<CustomizedAxisTick />} />
        <YAxis unit={unit} />
        <Tooltip cursor={{ fill: fillColors.tooltipCursor }} itemStyle={{color: '#4D4D4D'}} formatter={(value) => ( value + '' + unit )}/>
        <Legend onClick={handleClick('legend', onClick)} />
        {stacked ? (
          <Bar dataKey={dataKeys.uv} fill={uvColor || fillColors.uv} stackId="stack1" onClick={handleClick('bar', onClick, {dataKey: dataKeys.uv})} />
        ) : (
          <Bar dataKey={dataKeys.uv} fill={uvColor || fillColors.uv} />
        )}
        {dataKeys.pv !== undefined ? (
          stacked ? (
            <Bar dataKey={dataKeys.pv} fill={pvColor || fillColors.pv} stackId="stack1" onClick={handleClick('bar', onClick, {dataKey: dataKeys.pv})}/>
          ) : (
            <Bar dataKey={dataKeys.pv} fill={pvColor || fillColors.pv}/>
          )
        ) : (<React.Fragment/>)}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
