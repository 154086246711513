import React, { FC } from 'react';
import { Box, Flex, Heading, Text } from '@uniteplus/rhenus-design-system';

import Loading from './Loading';
import Track, { Status } from './Track';

interface Info {
  id: number;
  title: string;
  text: string;
}
interface ShippingDetails {
  id: number;
  title: string;
  info: string[];
}
interface Tracking {
  loading: boolean;
  infos?: Info[];
  track?: Status[];
  shippingDetails?: ShippingDetails[];
  onEdit?: (status: Status) => void;
  i18nForPackageTrackAndTrace?: any;
}

const Tracking: FC<Tracking> = ({
  loading = false,
  infos = [],
  track = [],
  shippingDetails = [],
  onEdit = () => {},
  i18nForPackageTrackAndTrace: t = {},
}: Tracking) => {
  if (loading) {
    return <Loading heading={t.shipmentTracking} />;
  }

  console.log(t);

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Box sx={{ bg: 'white', mb: [0, 1, 1], p: 4 }}>
        <Heading>{t.shipmentTracking || 'Sendungsverfolgung'}</Heading>
      </Box>
      <Flex sx={{ flexDirection: ['column', 'row', 'row'] }}>
        <Flex
          sx={{
            minWidth: 170,
            flexDirection: 'column',
            bg: 'white',
            mr: [0, 1, 1],
            p: 4,
          }}
        >
          <Text
            sx={{
              pb: 3,
              borderBottom: '1px solid #eeeeee',
              fontSize: 1,
              fontFamily: 'heading',
              color: 'black',
            }}
          >
            {t.informations || 'Informationen'}
          </Text>
          {infos.map((info) => (
            <Flex
              key={info.id}
              sx={{
                pt: 3,
                pb: 3,
                flexDirection: ['row', 'column', 'column'],
                justifyContent: 'space-between',
                borderBottom: '1px solid #eeeeee',
              }}
            >
              <Text sx={{ fontSize: 1, fontFamily: 'heading', color: 'black' }}>
                {info.title}
              </Text>
              <Text sx={{ fontSize: 1, fontFamily: 'body', color: 'black' }}>
                {info.text}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex sx={{ flex: '1 1 auto', flexDirection: 'column' }}>
          <Track track={track} onEdit={onEdit} buttonText={t.buttonText} />
          <Box
            sx={{
              flex: '1 0 auto',
              p: 4,
              mt: '1px',
              bg: 'white',
              display: ['none', 'inline'],
            }}
          >
            {/* SPACER */}
          </Box>
        </Flex>
      </Flex>
      <Flex
        sx={{
          p: 2,
          mt: [0, 1, 1],
          bg: 'white',
          flexDirection: ['column', 'row', 'row'],
          justifyContent: 'space-between',
        }}
      >
        {shippingDetails.map(({ id, title, info = [] }) => (
          <Flex key={id} sx={{ flexDirection: 'column', p: 2 }}>
            <Text sx={{ fontSize: 1, fontFamily: 'heading', color: 'black' }}>
              {title}
            </Text>
            {info.map((info, idx) => (
              <Text
                key={idx}
                sx={{ fontSize: 1, fontFamily: 'body', color: 'black' }}
              >
                {info}
              </Text>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Tracking;
