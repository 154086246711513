import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  Box,
  Grid,
  Flex,
  Text,
  Spinner,
} from '@uniteplus/rhenus-design-system';
import { PieChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TransportDistributionQuery } from '../__generated__/TransportDistributionQuery';

const transportDistributionQuery = gql`
  query TransportDistributionQuery {
    transport: transportsByCarrier(
      servicetype: Transport
      searchScope: International
    ) {
      dataKey
      dv1Int
    }
    paket: transportsByCarrier(servicetype: Paket, searchScope: International) {
      dataKey
      dv1Int
    }
    frachtfuehrerGruppen {
      id
      name
    }
  }
`;

const TransportDistribution = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery<TransportDistributionQuery>(
    transportDistributionQuery,
  );
  const missingData = {
    missingData: t(
      'pieChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!data || !data.transport) {
    return <div>{t('transportDistribution.noData', 'Keine Daten')}</div>;
  }

  const { transport = [], paket = [], frachtfuehrerGruppen = [] } = data;
  return (
    <Box sx={{ padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <Grid columns={[1, 2, 2]}>
        <Flex
          sx={{
            color: 'primary',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Text>
            {t(
              'dashboard.transportDistribution.parcelShipment',
              'Paketsendungen',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={paket.map((ds) => ({ name: ds.dataKey, value: ds.dv1Int }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${
                  frachtfuehrerGruppen.find((element) => element.name === name)
                    .id
                }`,
              )
            }
          />
        </Flex>
        <Flex
          sx={{
            color: 'primary',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Text>
            {t(
              'dashboard.transportDistribution.truckTransport',
              'LKW-Transporte',
            )}
          </Text>
          <PieChart
            height={220}
            messages={missingData}
            data={transport.map((ds) => ({
              name: ds.dataKey,
              value: ds.dv1Int,
            }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${
                  frachtfuehrerGruppen.find((element) => element.name === name)
                    .id
                }`,
              )
            }
          />
        </Flex>
      </Grid>
    </Box>
  );
};

export default TransportDistribution;
