import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { BarChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { TransportLaufzeitauswertungQuery } from '../__generated__/TransportLaufzeitauswertungQuery';

const transportLaufzeitauswertung = gql`
  query TransportLaufzeitauswertungQuery(
    $servicetype: Servicetype!
    $limit: Int!
    $startDate: String
    $endDate: String
  ) {
    transportLaufzeitauswertung(
      servicetype: $servicetype
      limit: $limit
      startDate: $startDate
      endDate: $endDate
    ) {
      dataKey
      dv1Int
      dv2Int
    }
  }
`;

const TransportLaufzeitauswertung = ({
  servicetype,
  limit = 10,
  startDate = new Date().getFullYear() + '-01-01',
  endDate = new Date().getFullYear() + '-12-01',
}) => {
  const { t } = useTranslation();
  const labelDeliveredOnTime = t(
    'dashboard.standardDeliveryTimeEvaluation.generalCargo.mapLegend.onTime',
    'Prozent Dossiere zugestellt on time',
  );
  const labelDeliveredNotOnTime = t(
    'dashboard.standardDeliveryTimeEvaluation.generalCargo.mapLegend.notInTime',
    'Prozent Dossiere nicht on time zugestellt',
  );
  const labelToFilterValues = {
    [labelDeliveredOnTime]: 'DeliveredOnTime',
    [labelDeliveredNotOnTime]: 'DeliveredDelayed',
  };
  const missingData = {
    missingData: t(
      'barChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };
  const history = useHistory();
  const { loading, data: queryData } = useQuery<any>( //TransportLaufzeitauswertungQuery
    transportLaufzeitauswertung,
    {
      variables: {
        servicetype,
        limit,
        startDate,
        endDate,
      },
    },
  );

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.transportLaufzeitauswertung) {
    return <div>{t('transportLaufzeitauswertung.noData', 'Keine Daten')}</div>;
  }

  const data = queryData.transportLaufzeitauswertung.map((ds) => ({
    name: ds.dataKey,
    [labelDeliveredOnTime]: ds.dv1Int,
    [labelDeliveredNotOnTime]: ds.dv2Int,
  }));

  return (
    <Box sx={{ height: 350, padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <BarChart
        data={data}
        messages={missingData}
        onClick={({ activeLabel: country, dataKey: performancestatus }) => {
          country
            ? history.push(
                `/transports/regellaufzeitauswertung/${servicetype}/${labelToFilterValues[performancestatus]}/${startDate}/${endDate}/${country}`,
              )
            : history.push(
                `/transports/regellaufzeitauswertung/${servicetype}/${labelToFilterValues[performancestatus]}/${startDate}/${endDate}`,
              );
        }}
        dataKeys={{
          uv: labelDeliveredOnTime,
          pv: labelDeliveredNotOnTime,
          xAxis: 'name',
        }}
        stacked={true}
        unit="%"
        uvColor="#85ce94"
        pvColor="#e28181"
      />
    </Box>
  );
};

export default TransportLaufzeitauswertung;
