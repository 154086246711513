import React, { useState } from 'react';
import TransportLaufzeitauswertung from './../dashboard/TransportLaufzeitauswertung';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@uniteplus/rhenus-reports';
import View from '../components/View';
import moment from 'moment';
import {
  Background,
  Grid,
  Card,
  Box,
  Heading,
  Text,
} from '@uniteplus/rhenus-design-system';

const Regellaufzeitauswertung = (props) => {
  const [startDate, setStartDate] = useState(moment().startOf('year').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('year').toDate());

  const cardHead = {
    p: 3,
    mb: '1px',
    bg: 'white',
    display: 'flex',
    alignItems: 'baseline',
  };
  const cardContent = { p: 3, bg: 'white' };
  const { t, i18n } = useTranslation();
  const locale = i18n?.language ? i18n.language?.split?.('_')?.[0] : 'de';
  return (
    <View>
      <Background url="/img/rhenus-background-ship.jpg" />

      <Grid gap={1} mx={2} mb={2} columns={[1, 1, 1]}>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t('standardDeliveryTimeEvaluation.period.title', 'Plandatum')}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <Grid justify="flex-start">
              <label>
                <Text>
                  {t('dashboard.standardDeliveryTimeEvaluation.from', 'von')}
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  locale={locale}
                />
              </label>
              <label>
                <Text>
                  {t('dashboard.standardDeliveryTimeEvaluation.until', 'bis')}
                </Text>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  locale={locale}
                />
              </label>
            </Grid>
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.generalCargo.detail.title',
                'Regellaufzeitauswertung pro Land (Stückgut)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Transport"
              limit={20}
              startDate={`${startDate.getFullYear()}-${startDate.getMonth() + 1
                }-01`}
              endDate={
                endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-01'
              }
            />
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.parcel.detail.title',
                'Regellaufzeitauswertung pro Land (Paket)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Paket"
              limit={20}
              startDate={
                startDate.getFullYear() +
                '-' +
                (startDate.getMonth() + 1) +
                '-01'
              }
              endDate={
                endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-01'
              }
            />
          </Box>
        </Card>
      </Grid>
    </View>
  );
};

export default Regellaufzeitauswertung;
